import { motion, useAnimation } from "framer-motion";
import React, { useMemo, useCallback } from "react";
import BackgroundImage from "../../assets/images/bg.png";
import LogoImage from "../../assets/images/backgroundLogo.svg";
import AppStoreBadge from "../../assets/images/appStore.svg";
import PlayStoreBadge from "../../assets/images/playStore.svg";
import { useLanguage } from "../../components/languageprovider/index";
import languages from "../../constants/language";

// Reusable button component
const LanguageButton = React.memo(({ country, onClick }) => (
  <button
    onClick={onClick}
    className="transform hover:scale-110 transition-transform duration-300"
  >
    <img
      className="w-12 md:w-16 lg:w-20 rounded-lg cursor-pointer"
      src={country.flags.svg}
      alt={country.name}
      loading="lazy"
    />
  </button>
));

// Reusable video button component
const VideoButton = React.memo(({ color, videoUrl, flagSrc }) => (
  <button
    onClick={() => window.open(videoUrl, "_blank")}
    className={`bg-${color}-500 hover:bg-${color}-600 px-4 md:px-6 py-3 md:py-4 rounded-2xl flex items-center justify-center gap-3 md:gap-4 min-w-[140px] md:min-w-[160px] group relative overflow-hidden transition-all duration-300 hover:scale-105 shadow-lg`}
  >
    <div className="absolute inset-0 bg-white/10 group-hover:bg-white/20 transition-all duration-300"></div>
    <div className="relative flex items-center gap-2 md:gap-3">
      <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-white/30 flex items-center justify-center">
        <img
          className="w-5 h-5 md:w-6 md:h-6"
          alt="YouTube"
          src="https://img.icons8.com/color/48/000000/youtube-play.png"
          loading="lazy"
        />
      </div>
      <div className="w-8 h-8 md:w-10 md:h-10 rounded-full overflow-hidden border-2 border-white/40">
        <img
          src={flagSrc}
          alt="flag"
          className="w-full h-full object-cover"
          loading="lazy"
        />
      </div>
    </div>
    <div className="absolute bottom-0 left-0 right-0 h-1 bg-white/40 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
  </button>
));

// Reusable store button component
const StoreButton = React.memo(({ badge, text, onClick }) => (
  <button
    onClick={onClick}
    className="bg-white/95 hover:bg-white px-4 md:px-6 py-3 md:py-4 gap-3 rounded-xl flex flex-row items-center justify-center cursor-pointer hover:scale-105 transition-all duration-300 w-full md:w-auto min-w-[280px] shadow-lg hover:shadow-xl relative z-10 touch-manipulation"
  >
    <img
      className="w-10 md:w-12 lg:w-14 h-auto pointer-events-none"
      src={badge}
      alt="Store Badge"
      loading="lazy"
    />
    <span className="text-black font-bold text-base md:text-lg lg:text-xl whitespace-nowrap pointer-events-none">
      {text}
    </span>
  </button>
));

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const lang = currentLanguage;
  const texts = languages[lang];
  const controls = useAnimation();

  React.useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const countries = useMemo(
    () => [
      {
        id: "de",
        name: "German",
        flags: {
          svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
        },
      },
      {
        id: "tr",
        name: "Turkish",
        flags: {
          svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
        },
      },
      {
        id: "en",
        name: "English",
        flags: {
          svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
        },
      },
    ],
    []
  );

  const handleLanguageChange = useCallback(
    (id) => {
      changeLanguage(id);
    },
    [changeLanguage]
  );

  const handleStoreClick = useCallback(() => {
    window.open("https://a-flying.com/link", "_blank");
  }, []);

  return (
    <motion.div
      className="relative min-h-screen flex flex-col"
      animate={controls}
      initial={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={BackgroundImage}
          alt="Background"
          loading="eager"
        />
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>

      <div className="relative flex-1 flex flex-col items-center text-center px-4 py-4 md:py-6">
        <motion.div
          className="flex flex-wrap gap-3 md:gap-4 items-center justify-center mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          {countries.map((country) => (
            <LanguageButton
              key={country.id}
              country={country}
              onClick={() => handleLanguageChange(country.id)}
            />
          ))}
        </motion.div>

        <motion.div
          className="flex flex-wrap gap-3 md:gap-4 items-center justify-center mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <VideoButton
            color="orange"
            videoUrl="https://youtu.be/FP3hXcnHdAQ?si=uEYq7UTLdVts6okr"
            flagSrc={countries[0].flags.svg}
          />
          <VideoButton
            color="red"
            videoUrl="https://youtu.be/PD-96MOZT88"
            flagSrc={countries[1].flags.svg}
          />
          <VideoButton
            color="blue"
            videoUrl="https://youtu.be/hTaC8ADLbLQ?si=jS1P92lLc9U68XQZ"
            flagSrc={countries[2].flags.svg}
          />
        </motion.div>

        <motion.div
          className="flex flex-col items-center gap-2 mb-6"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <img
            className="w-3/4 md:w-1/2 lg:w-2/5 hover:scale-105 transition-transform duration-300"
            src={LogoImage}
            alt="Logo"
            loading="eager"
          />
          <span className="font-[Inter] font-bold text-2xl md:text-3xl lg:text-4xl text-white">
            {texts.appTitle}
          </span>
          <span className="font-[Inter] text-lg md:text-xl text-white max-w-2xl">
            {texts.appDescription}
          </span>
        </motion.div>

        <motion.div
          className="w-full flex flex-col md:flex-row gap-4 items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <div className="w-full md:w-auto flex justify-center">
            <StoreButton
              badge={AppStoreBadge}
              text={texts.downloadFromAppStore}
              onClick={handleStoreClick}
            />
          </div>
          <div className="w-full md:w-auto flex justify-center">
            <StoreButton
              badge={PlayStoreBadge}
              text={texts.downloadFromPlayStore}
              onClick={handleStoreClick}
            />
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default React.memo(Index);
