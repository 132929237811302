import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../components/languageprovider';
import languages from '../../../constants/language';

const ResultStep = ({ isSuccess }) => {
  const { currentLanguage } = useLanguage();
  const lang = currentLanguage;
  const texts = languages[lang];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="w-full max-w-md mx-auto p-6 bg-white rounded-xl shadow-lg"
    >
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          {isSuccess ? texts.resetPasswordSuccess : texts.resetPasswordFailed}
        </h2>
        <p className="text-gray-600">
          {isSuccess ? texts.resetPasswordSuccessMessage : texts.resetPasswordFailedMessage}
        </p>
      </div>

      <div className="flex justify-center">
        {isSuccess ? (
          <svg className="w-16 h-16 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        ) : (
          <svg className="w-16 h-16 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        )}
      </div>
    </motion.div>
  );
};

export default ResultStep; 