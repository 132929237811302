import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLanguage } from "../../components/languageprovider";
import languages from "../../constants/language";
import { API_URL, API_PORT } from "../../constants/appConfig";
import {
  emailResetRequest,
  codeResetRequest,
  passwordResetRequest,
} from "./api";
import EmailStep from "./components/EmailStep";
import CodeStep from "./components/CodeStep";
import NewPasswordStep from "./components/NewPasswordStep";
import ResultStep from "./components/ResultStep";

const PasswordReset = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const lang = currentLanguage;
  const texts = languages[lang];
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [resetData, setResetData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const countries = [
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
  ];

  const handleEmailSubmit = async (email) => {
    setLoading(true);
    try {
      const response = await emailResetRequest(email);
      if (response) {
        setResetData({
          resetId: response?.id,
          userId: response?.user?.userId,
        });
        setEmail(email);
        setStep(2);
      }
    } catch (error) {
      setError(texts.requestFailed);
      console.error("Email reset request failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCodeSubmit = async (code) => {
    setLoading(true);
    setError("");
    try {
      const response = await codeResetRequest(resetData?.resetId, code);
      if (response) {
        setResetData({ ...resetData, code });
        setCode(code);
        setStep(3);
        return true;
      }
      return false;
    } catch (error) {
      setError(texts.requestFailed);
      console.error("Code validation failed:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleNewPasswordSubmit = async (password) => {
    setLoading(true);
    setError("");
    try {
      const response = await passwordResetRequest(
        resetData?.userId,
        resetData?.resetId,
        resetData?.code,
        password
      );

      if (response) {
        setIsSuccess(true);
        setStep(4);
        return true;
      }
      return false;
    } catch (error) {
      setError(texts.requestFailed);
      console.error("Password reset failed:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-lg">
        <div className="text-center mb-8">
          <motion.div
            className="flex flex-wrap gap-4 items-center justify-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            {countries.map((country, index) => (
              <button
                key={index}
                onClick={() => {
                  changeLanguage(country.id);
                }}
              >
                <img
                  className="w-16 rounded-lg cursor-pointer hover:scale-110 transition ease 1s"
                  src={country.flags.svg}
                  alt={country.name}
                />
              </button>
            ))}
          </motion.div>
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            {texts.resetPassword}
          </h1>
          <p className="text-gray-600">
            {texts.step} {step}/4
          </p>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600 text-center">
            {error}
          </div>
        )}

        <AnimatePresence mode="wait">
          {step === 1 && (
            <motion.div
              key="email"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
            >
              <EmailStep onNext={handleEmailSubmit} loading={loading} />
            </motion.div>
          )}
          {step === 2 && (
            <motion.div
              key="code"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
            >
              <CodeStep onNext={handleCodeSubmit} loading={loading} />
            </motion.div>
          )}
          {step === 3 && (
            <motion.div
              key="password"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
            >
              <NewPasswordStep
                onNext={handleNewPasswordSubmit}
                loading={loading}
              />
            </motion.div>
          )}
          {step === 4 && (
            <motion.div
              key="result"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
            >
              <ResultStep isSuccess={isSuccess} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PasswordReset;
