import { API_URL, API_PORT } from "../../constants/appConfig";

const emailResetRequest = async (email) => {
  try {
    const response = await fetch(
      `${API_URL}:${API_PORT}/passwordReset/request?email=${encodeURIComponent(
        email
      )}`
    );
    if (!response.ok) {
      throw new Error("Failed to send reset code");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Email reset request failed:", error);
    throw error;
  }
};

const codeResetRequest = async (resetId, code) => {
  try {
    const response = await fetch(
      `${API_URL}:${API_PORT}/passwordReset/validate?code=${encodeURIComponent(
        code
      )}&resetId=${encodeURIComponent(resetId)}`
    );
    if (!response.ok) {
      throw new Error("Failed to verify code");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Code validation failed:", error);
    throw error;
  }
};

const passwordResetRequest = async (userId, resetId, code, newPassword) => {
  try {
    const response = await fetch(`${API_URL}:${API_PORT}/passwordReset/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        resetId,
        code,
        newPassword,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to reset password");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Password reset failed:", error);
    throw error;
  }
};

export { emailResetRequest, codeResetRequest, passwordResetRequest };
